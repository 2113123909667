.menu{
    justify-content: center;
}

.content{
    width: var(--main-width);
    height: 80px;

    justify-content:space-between ;
    align-items: center;

    padding: 0 50px 0 50px;
}

.logo{
    width: 120px;

    cursor: pointer;

    color: #FFF;
}

.menu .button{ 
    width: 130px;
    height: 35px;

    align-items: center;
    justify-content: center;

    background: none;
    border: 1px solid var(--main-color-light-blue);
    border-radius: 5px;

    margin-left: 20px;

    font-size: 10pt;
    text-decoration: none;
    color: var(--main-color-light-blue);

    cursor: pointer;
    transition: 0.5s;
}

.menu .button:hover{
    border-radius: 10px;
}
