footer{
    display: flex;
    width: 100%;
    height: 80px;

    align-items: center;
    justify-content: center;

    color: white;
    background: var(--main-color-grayish-dark-blue);
}

.footer{
    width: var(--main-width);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

.footer .nav{
    width: 420px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.button-container{
    width: 300px;
    justify-content: space-between;
}

.button-container .button{
    align-items: center;

    border: none;

    color: white;
    background: none;

    cursor: pointer;
}

.footer a{
    align-items: center;

    text-decoration: none;
    font-size: 12px;
    
    color: white;

    cursor: pointer;
}

.social{
    width: 300px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.social a{
    width: 30px;
    height: 30px;
}