.home{
    align-items: center;
}

header{
    width: 100%;
    justify-content: center;
    
    margin-bottom: 50px;
    padding: 50px 60px 50px 60px;
    
    background: linear-gradient(45deg, var(--main-color-light-blue) -65%, var(--main-color-blue) 110%);
}

.presentation{
    align-items: center;
    justify-content: space-around;

    width: var(--main-width);

    padding: 0 50px 0 50px;
}

.presentation .highlighted{
    font-size: 35;
}

header h1{
    font-family: 'Ibarra Real Nova', serif;
    text-align: center;
    color: white;
}

.home header a{
    color: white;
    border: 1px solid white;

    margin-top: 50px;
}

.home a{
    display: flex;
    
    justify-content: center;
    align-items: center;

    width: 200px;
    height: 48px;
    
    text-decoration: none;
    color: black;
    
    cursor: pointer;
    
    border-radius: 5px;
    border: 1px solid black;
    transition: 0.5s;
}

.home a:hover{
    border-radius: 10px;
}

.home a img{
    width: 48px;
    padding: 18px;
    margin-right: 24px;
}

section{
    display: flex;
    width: var(--main-width);
    margin-bottom: 150px;
    
    flex-direction: row;
    justify-content: space-between;
}

section .img{
    justify-content: end;
    width: 540px;
    height: 600px;

    border-radius: 10px;

    background: #c0c0c0;
}

section h2, section h3{
    max-width: 370px;
    margin-bottom: 30px;

    font-size: 30pt;
    line-height: 30pt;
}

.skills{
    width: var(--main-width);
    flex-direction: row;
    justify-content: space-between;
    
    color: white;
    text-align: center;
    align-items: flex-start;
}

.skills h2{
    padding-top: 5px;

    font-size: 16pt;
    line-height: 16pt;
    color: var(--main-color-blue);
}

.skills-description{
    width: var(--main-width);
    height: 200px;

    padding: 50px;

    border-bottom: 1px solid rgba(0,0,0,0.2);

    border-radius: 10px;
}

.skills-description p{
    font-size: 13pt;
    line-height: 30px;
}

.icon-box{
    width: 100px;
    height: 100px;

    align-items: center;
    justify-content: center;

    border-radius: 10px;
    
    background: linear-gradient(45deg, var(--main-color-light-blue) -65%, var(--main-color-blue) 110%);

    cursor: pointer;
    transition: 0.5s;
}

.icon-box:hover{
    border-radius: 15px;
    background: linear-gradient(90deg, var(--main-color-light-blue) -65%, var(--main-color-blue) 110%);
}

.icon-box img{
    width: 60%;
    color: white;
}

#about{
    width: 430px;
    padding-top: 50px;

    flex-direction: column;

    /* border-top: 1px solid rgba(0,0,0,0.2); */
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

#about p{
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 24px;
}