:root {
    --main-color-light-blue: #2c8cf4;
    --main-color-dark-blue: #2E4B85;
    --main-color-grayish-dark-blue: #33323D;
    --main-color-gray: #c0c0c0;
    --main-color-blue: #2d2db0;
    --main-width: 1200px;
  }

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body *{
    display: flex;

}

.container{
    width: 100vw;
    flex-direction: column;
    box-sizing: border-box;
}

.flex{
    display: flex;
}

.align-center{
    align-items: center
}

.justify-center{
    justify-content: center;
}

.full-center{
    justify-content: center;
    align-items: center; 
}

.flex-column{
    flex-direction: column;
}

.flex-row{
    flex-direction: row;
}

.logo-footer{
    width: 50px;
}

h1, h2, h3{
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
}

h1{
    font-size: 40pt;
    line-height: 50pt;
}

h2{
    font-size: 20pt;
    line-height: 30pt;
}

h3{
    font-size: 18pt;
    line-height: 28pt;
}

a, p, label, span, button{
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12pt;
}